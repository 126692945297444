import API from "api";

export default {

    fetchProducts(request, successCallback, errorCallback) {
        API.POST({
            url: `/commerce/cannabis/fetch`,
            params: request
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    getUser(successCallback, errorCallback) {
        API.GET({
            url: `/auth/users/me`
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },

    signOut(successCallback, errorCallback) {
        API.GET({
            url: `/auth/signout`
        }, (data) => {
            if (typeof successCallback === 'function') {
                successCallback(data);
            }
        }, (error) =>{
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        });
    },
}
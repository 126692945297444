import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import PublicMenu from "./publicMenu";
import ScrollTop from "../shared/util/scrollTop";
import cx from "classnames";
import {MobileView} from "react-device-detect";
import styles from "./publicStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PublicContext from "./publicContext";
import PublicFooter from "./publicFooter";
import PublicHeader from "./publicHeader";

const useStyles = makeStyles(styles);

export default function PublicLayout(props) {
    const {children, noPadding, title, header, icon} = props;
    const classes = useStyles();
    const showNotifications = false;
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [dockOpen, setDockOpen] = useState(true);

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    const [featureMap, setFeatureMap] = useState({
        branding: {
            shipsAndSoldBy: false
        }
    })

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const isFeature = (featureGroup, featureName) => {
        return featureMap[featureGroup][featureName];
    }

    let combinedRoot = cx({
        [classes.rootPadding]: !noPadding,
    });

    const onDockToggle = () => {
        setDockOpen(!dockOpen);
    };

    return (
        <React.Fragment>
            <PublicContext.Provider value={{
                onDockOpen: onDockToggle,
                onDockClose: onDockToggle,
                isFeature: isFeature,
                width: width,
                height: height
            }}>
                <PublicMenu dockOpen={dockOpen}/>
                {icon && <PublicHeader title={title} header={header} Icon={icon}/>}
                <div className={combinedRoot}>
                    {children}
                </div>
                <MobileView>
                    <ScrollTop/>
                </MobileView>
                <div style={{marginBottom: 20}}>&nbsp;</div>
                <PublicFooter/>
            </PublicContext.Provider>
        </React.Fragment>
    );
}

PublicLayout.propTypes = {
    children: PropTypes.any
}
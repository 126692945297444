import React from 'react';
import styles from "./aboutUsStyles";
import {Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function AboutUsHistoryOfBHA(props) {
    const classes = useStyles();

    return (
        <Paper style={{padding: 20}}>
            <Typography variant="h5">
                Blessed Hope Academy – "Go and build one."
            </Typography>
            <br/>
            <div>
                The story of Alice Ann Ashcraft, Founder of Blessed Hope Academy, is about obedience, blessings, and the
                favor of God that lasted a lifetime. In retrospect, "His eye was on the sparrow" when Alice, at age five, was
                taken to a Pentecostal church by neighbors and anointed with oil by the pastor. At age nine, her new neighbors
                took her to a Baptist Church where she was baptized in water. She had no other religious training until, as an
                adult, she was sick. Then, when she asked God a question, He answered in a life-changing way.
            </div><br/>
            <div>
                Alice A. Ashcraft was a successful realtor in the 1970’s. She became so successful in real estate she opened her
                own Investment brokerage firm in the city of Alamo Heights, Texas with thirteen realtors working in her firm.
                However, with all of her success, she was unfortunately plagued with a chronic illness that kept her struggling
                to manage her business and life. She sought help from doctor after doctor and test after test with little help to
                manage her illness. She was constantly seeking help wherever she could get it. By the grace of God, she
                accidentally received her neighbor’s magazine in the mail. On the cover was a big picture of the Prayer Tower
                at Oral Roberts University. Alice immediately felt she needed to go to this beautiful Prayer Tower for prayer
                and healing. With her aunt, she drove ten hours to reach Tulsa, Oklahoma.
            </div><br/>
            <div>
                At the ORU campus, university students prayed for Alice in the Prayer Tower, and she received the Holy Spirit.
                Later, while praying alone in the adjoining gardens, she was taken up to a heavenly place to gaze upon the
                world with Jesus. He showed her the earth from His viewpoint, imparted His love for the world to her, and sent
                her back to the garden where she had been praying. As Alice continued to pray and admire her surroundings,
                she wondered in her heart, "Why aren’t there more schools like this?" The next day, God called her into the
                ministry to open a Christian school, using the whole person model of ORU by educating the mind, body, and
                spirit. He simply said to her, "Go and build one."
            </div><br/>
            <div>
                With young children of her own, Alice felt inspired by God to start a school for younger students. She began
                her endeavor to "build one" with an addition to her house. Blessed Hope Academy was born in the summer of
                1975 in a large 100-year-old restored two-story home on two acres of land located in NW San Antonio, Texas.
                The school began with only five students, a principal, an academic teacher, and a music teacher. The first school
                year went well. In fact, too well. The school quickly grew out of Alice’s home addition with sixty-five students
                enrolled to start for the 1976-1977 school year. Finding a new building became Alice Ashcraft’s new mission!
                The school went on to have multiple new buildings in the years to come and eventually concentrated on older
                students in high school where the need was most urgent at the time.
            </div><br/>
            <div>
                Alice sold her brokerage firm and worked full time at Blessed Hope Academy until she left this world to be
                with Jesus. Throughout the years, God has always provided, and He remains faithful. Blessed Hope Academy
                has helped thousands of students from all walks of life and from all over the world graduate from high school.
                And many students found Jesus on the way, which was the plan all along.
            </div><br/>
            <div>
                "Mrs. Ashcraft, why the name Blessed Hope?" was a question Alice often answered. She would tell students
                she wanted the name of the school to be related to Jesus in some way, and "Jesus is the Blessed Hope for
                mankind, their second chance in life."
            </div><br/>
            <div style={{textAlign: "center"}}>
                ...looking for that blessed hope,<br/>
                and the glorious appearing of the great God and our Savior Jesus Christ;<br/>
                Titus 2:13 KJV<br/>
            </div><br/>
            <div>
                Alice wanted, with God’s help, to offer hope and a future (Jeremiah 29:11), a second chance for students
                wanting to earn a high school diploma
            </div><br/>
            <div>
                Alice A. Ashcraft went to be with her Lord and Savior, April 6, 2018. She is truly missed by her family, friends,
                and students, but her legacy of Blessed Hope Academy lives on in her honor.
            </div><br/>
            <div>
                Based in part on the book, <span style={{textDecoration: "underline"}}>BLESSED HOPE For God So Loved the World</span>, written by Alice Ashcraft.
                Published in 2017 by Christian Faith Publishing, Inc. in Meadville, Pennsylvania.
            </div>
        </Paper>
    );
}
import React, {useEffect, useState} from 'react';
import {ArrowUpward} from "@material-ui/icons";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

export default function ScrollTop() {
    const [showScroll, setShowScroll] = useState(false)

    useEffect(()=>{
        window.addEventListener('scroll', checkScrollTop);
        return function cleanup() {
            window.removeEventListener('scroll', checkScrollTop);
        }
    })

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400){
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400){
            setShowScroll(false);
        }
    };

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <Grid container alignItems="center" justifyContent="center" onClick={scrollTop} style={{marginTop: 40, cursor: "pointer"}}>
            <Grid item>
                <Typography variant="h5">Top</Typography>
            </Grid>
            <Grid item>
                <ArrowUpward className="scrollTop" style={{height: 40, display: showScroll ? 'flex' : 'none'}}/>
            </Grid>
        </Grid>
    );
}
import React, {useState} from 'react';
import styles from "./aboutUsStyles";
import PublicLayout from "components/layout/publicLayout";
import {MenuBook} from "@material-ui/icons";
import {Box, Typography} from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {makeStyles} from "@material-ui/core/styles";
import AboutUsMissionStatement from "./aboutUsMissionStatement";
import AboutUsHistoryOfBHA from "./aboutUsHistoryOfBHA";
import AboutUsATributeToOurFounder from "./aboutUsATributeToOurFounder";
import AboutUsStudentTestimonials from "./aboutUsStudentTestimonials";
import {isBrowser} from "react-device-detect";
import AboutUsAccreditation from "./aboutUsAccreditation";

const useStyles = makeStyles(styles);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <React.Fragment>
                    <Typography>{children}</Typography>
                </React.Fragment>
            )}
        </div>
    );
}

export default function AboutUsHome(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <PublicLayout title="About Us" icon={MenuBook}>
            <Tabs
                style={{marginTop: 10, backgroundColor: "rgba(244, 244, 244, 0.29)", maxWidth: "100%"}}
                value={value}
                orientation={isBrowser ? "horizontal" : "vertical"}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab classes={{root: classes.tabRoot}} label="Mission Statement" />
                <Tab classes={{root: classes.tabRoot}} label="Accreditation" />
                <Tab classes={{root: classes.tabRoot}} label="History of B.H.A." />
                <Tab classes={{root: classes.tabRoot}} label="A Tribute to our Founder" />
                <Tab classes={{root: classes.tabRoot}} label="Student Testimonials" />
            </Tabs>
            <div style={{padding: isBrowser ? "40px 50px 0 50px" : "20px 25px 0 25px"}}>
                <TabPanel value={value} index={0}>
                    <AboutUsMissionStatement/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <AboutUsAccreditation/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <AboutUsHistoryOfBHA/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <AboutUsATributeToOurFounder/>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <AboutUsStudentTestimonials/>
                </TabPanel>
            </div>
        </PublicLayout>
    );
}
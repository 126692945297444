import React from 'react';
import styles from "./contactStyles";
import PublicLayout from "components/layout/publicLayout";
import {Store} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import {isBrowser} from "react-device-detect";

const useStyles = makeStyles(styles);

export default function ContactHome(props) {
    const classes = useStyles();


    return (
        <PublicLayout title="Contact" icon={Store}>
            <div style={{padding: "40px 50px 0 50px"}}>
                <Grid container spacing={3}>
                    <Grid item xs={isBrowser ? 4 : 12} style={{textAlign: "center"}}>
                        <img width="90%" style={{padding: "0 20px 0 20px"}}
                             src={`${process.env.PUBLIC_URL}/images/map.jpeg`} alt="BHA Location Map" />
                    </Grid>
                    <Grid item xs={isBrowser ? 8 : 12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{textAlign: "left"}}>
                                <strong>Blessed Hope Academy</strong><br/>
                                3355 Cherry Ridge Dr. #200A<br/>
                                San Antonio, TX 78230
                            </Grid>
                            <Grid item xs={12} style={{textAlign: "left"}}>
                                <div><strong>Contact Us</strong></div>
                                Phone: (210) 697-9191<br/>
                                Fax: (210) 239-6337<br/>
                                E-mail: <a href="mailto:office@blessedhopeacademy.com">office@blessedhopeacademy.com</a>
                            </Grid>
                            <Grid item xs={12} style={{textAlign: "left"}}>
                                <div><strong>School & Office Hours</strong></div>
                                <Grid container>
                                    <Grid item xs={3}>
                                        Monday
                                    </Grid>
                                    <Grid item xs={9}>
                                        Closed
                                    </Grid>
                                    <Grid item xs={3}>
                                        Tuesday
                                    </Grid>
                                    <Grid item xs={9}>
                                        12:30pm – 5:30pm
                                    </Grid>
                                    <Grid item xs={3}>
                                        Wednesday
                                    </Grid>
                                    <Grid item xs={9}>
                                        10:00am – 1:00pm
                                    </Grid>
                                    <Grid item xs={3}>
                                        Thursday
                                    </Grid>
                                    <Grid item xs={9}>
                                        12:30pm – 5:30pm
                                    </Grid>
                                    <Grid item xs={3}>
                                        Friday
                                    </Grid>
                                    <Grid item xs={9}>
                                        Closed
                                    </Grid>
                                </Grid>
                                {/*<Grid container>*/}
                                {/*    <Grid item xs={3}>*/}
                                {/*        Monday*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={9}>*/}
                                {/*        Closed*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={3}>*/}
                                {/*        Tuesday*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={9}>*/}
                                {/*        12:00pm – 4:00pm*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={3}>*/}
                                {/*        Wednesday*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={9}>*/}
                                {/*        10:00am – 1:00pm*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={3}>*/}
                                {/*        Thursday*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={9}>*/}
                                {/*        12:00pm – 4:00pm*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={3}>*/}
                                {/*        Friday*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={9}>*/}
                                {/*        Closed*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </PublicLayout>
    );
}
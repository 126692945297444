import {isBrowser} from "react-device-detect";

const platformStyles = {
    platformLinkNoPadding: {
        padding: 0
    },
    platformLink: {
        fontSize: 16,
        height: 40,
        fontWeight: 500,
        textTransform: "none",
        borderRadius: 10,
        color: "#5cdb94"
    },
    platformLinkInverse: {
        color: "#2c232d"
    },
    platformButtonInline: {
        marginTop: 10,
    },
    platformButtonContained: {
        backgroundColor: "#5cdb94",
    },
    platformButtonOutlined: {
        backgroundColor: "#2c232d",
    },
    platformButton: {
        //backgroundColor: "magenta",
        fontSize: 16,
        fontWeight: 500,
        height: 30,
        textTransform: "none",
        //borderRadius: 10
    },
    platformButtonSmall: {
        width: 100
    },
    platformTableVisuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    platformTableToolbarRoot: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    platformTableToolbarHighlight: {
        color: "red",
        backgroundColor: "blue",
    },
    platformTableToolbarTitle: {
        flex: '1 1 100%',
    },
    platformButtonLarge: {
        // width: 200,
        // fontSize: "1.1rem",
        // height: 40,
        // fontWeight: "normal"
        width: 300,
        fontSize: 18,
        height: 55,
        fontWeight: "normal"
    },
    platformTableEmptyTextInverted: {
        color: "whitesmoke !important"
    },
    platformTableEmptyText: {
        textTransform: "none",
        fontWeight: "bold",
        color: "white"
    },
    platformTableEmpty: {
        width: "100vw",
        padding: 100,
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        //backgroundColor: "#fbfbfd"
    },
    platformButtonXLarge: {
        width: 300,
        fontSize: 18,
        height: 55,
        fontWeight: "normal"
    },
    platformButtonMedium: {
        width: 200,
        fontSize: 18,
        height: 45,
        fontWeight: "normal"
    },
    platformBackButton: {
        border: "1px solid #f3f3f3",
        width: 40,
        color: "white",
        paddingTop: 7,
        cursor: "pointer"
    },
    platformButtonFullWidth: {
        width: "100%",
    },
    platformBox: {
        textAlign: "center"
    },
    platformButtonCapsule: {
        borderRadius: 5,
        width: isBrowser ? 500 : "90vw",
        height: 75,
        display: "table",
        marginBottom: 15,
        cursor: "pointer"
    },
    platformButtonCapsuleLabel: {
        fontSize: 17,
        fontWeight: 700,
        marginBottom: 10
    },
    platformButtonCapsuleSelected: {
        border: "2px solid lightGreen"
    },
    platformButtonCapsuleDeselected: {
        border: "1px solid rgba(0, 0, 0, .3)",
        marginLeft: 1
    },
    platformButtonCapsuleHover: {
        border: "1px solid black",
        marginLeft: 1
    },
    platformButtonCapsuleItem: {
        display: "table-cell",
        verticalAlign: "middle",
        padding: 15
    },
    platformButtonCapsuleItemText: {
        fontWeight: 500,
        fontSize: 17
    },
    platformButtonCapsuleItemAdornment: {
        textAlign: "right",
        fontSize: 17
    },
    platformInput: {
        //width: 500,
        borderRadius: 5,
        backgroundColor: "#f6f3f2"
    },
    platformInputFullWidth: {
        width: "100%",
    },
    platformSelect: {
        flexGrow: 1,
        backgroundColor: "#f6f3f2",
        marginRight: 20,
        minHeight: "1.3em"
        //width: "100%",
    },
    platformSelectMd: {
        width: 500,
    },
    platformFormControl: {
        width: "100%"
    },
    platformFormLabel: {
        margin: "10px 0 10px 0",
        fontWeight: 400,
        color: "black",
        opacity: 1,
        flexGrow: 1
    },
    platformFormContainer: {
        //display: "inline-block",
    },
    platformFormContainerInline: {
        display: "flex",
    },
    platformSpinnerButton: {

    },
    platformListItemRoot: {
        marginTop: 0,
        marginBottom: 0
    },
    platformListItemPrimary: {
        fontSize: 14,
        color: "rgb(51, 51, 51)"
    },
    platformImage: {
        opacity: 0,
    },
    platformImageLoaded: {
        opacity: 1
    },
    tableHead: {
        borderBottomStyle: "solid !important",
        borderBottomWidth: "5px !important"
    },
    tableHeadCell: {
        color: "white",
        fontSize: "1rem"
    },
    tableRow: {
        '& > *': {
            borderBottom: 'unset',
        },
        //backgroundColor: "#202224",
        //color: "white"
    },
    platformTableFilterBar: {
        //background: "linear-gradient(0.25turn, #A177FF, #23744CFF)",
        //background: "linear-gradient(0.25turn, #A177FF, #02CEFF)", // HBO MAX
        backgroundColor: "#323436",
        paddingLeft: 20,
        paddingTop: 20,
        paddingBottom: 15,
        color: "black",
        display: "flex"
    },
    platformChipRoot: {
        borderRadius: 4,
        margin: 2
    },
    platformChip: {
        fontSize: "0.85rem",
        fontWeight: 200,
        color: "black"
    },
    platformModelAppBar: {
        //background: "linear-gradient(0.25turn, #A177FF, #23744CFF)",
        //background: "linear-gradient(0.25turn, #A177FF, #02CEFF)", // HBO MAX
        backgroundColor: "#2c232d",
        color: "black"
    },
    platformModelToolbar: {
        //width: "98vw",
        color: "white",
        minHeight: 0,
        height: 50,
        paddingRight: 50
    },
    platformModelToolbarText: {
        color: "white",
        fontSize: "1.2rem",
        fontWeight: 400,
    },
    platformCard: {
        marginLeft: "auto",
        marginRight: "auto",
        //minHeight: 300,
        //boxShadow: "0 0 0 3px white inset",
        //border: "solid 5px #87db93",
        backgroundColor: "#2c232d",
        borderRadius: 0,
        //backgroundImage: "repeating-linear-gradient(-45deg,transparent,transparent 5px,#202224 5px,#1B1C1EFF 10px)"
    },
    platformCardHeader: {
        color: "white",
        fontSize: "1.1rem",
        fontWeight: 400,
        padding: 10,
        minHeight: 40,
        backgroundColor: "#493750",
        borderBottomWidth: 5,
        borderBottomStyle: "solid",
        borderBottomColor: "#5cdb94",
        //backgroundImage: "repeating-linear-gradient(-45deg,transparent,transparent 5px,#323436 5px,#3C3F41FF 10px)"
    },
    platformCardBody: {
        color: "whitesmoke",
        fontSize: "0.9rem",
        padding: 20
    },
    platformProgressColorPrimary: {
        backgroundColor: "magenta",
    },
    platformProgressColorSecondary: {
        backgroundColor: "#B2DFDB",
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 1,
        marginTop: 5,
        left: 0,
        right: 0
    },
    suggestion: {
        display: "block"
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none"
    },
};

export default platformStyles;
import Radium from "radium";
import {fadeInUp} from "react-animations";
import {isBrowser} from "react-device-detect";

const homeStyles = {
    featureText: {
        color: "white",
        fontWeight: 700,
        fontSize: 24,
        width: "80%"
    },
    newsLink: {
        fontWeight: 500,
        fontSize: 18,
        height: 25,
        marginTop: isBrowser ? 79 : 0,
        backgroundColor: "#5cdb94",
        textAlign: "center",
        textDecoration: "underline",
        color: "white",
        cursor: "pointer"
    },
    featureLeftText: {
        top: 80,
        left: "10vw",
        animation: 'x 3s',
        position: "absolute",
        width: "50vw",
        animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
    },
    featureRightText: {
        top: 50,
        right: "30vw",
        animation: 'x 3s',
        position: "absolute",
        width: "10vw",
        animationName: Radium.keyframes(fadeInUp, 'fadeInUp')
    },
    homeCard: {
        padding: 20,
        height: isBrowser ? 450 : 600,
        backgroundColor: "#fffdfd"
    },
    homeTileImage: {
        top: "80%"
    }
};

export default homeStyles;
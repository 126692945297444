import React from 'react';
import styles from "./homeStyles";
import PublicLayout from "components/layout/publicLayout";
import {makeStyles} from "@material-ui/core/styles";
import HomeFeatureBrowser from "./homeFeatureBrowser";
import HomeTiles from "./homeTiles";
import HomeNews from "./homeNews";
import {isBrowser, isMobile} from "react-device-detect";
import HomeFeatureMobile from "./homeFeatureMobile";

const useStyles = makeStyles(styles);

export default function HomeHome(props) {
    const classes = useStyles();


    return (
        <PublicLayout>
            <HomeNews/>
            {isBrowser && <HomeFeatureBrowser/>}
            {isMobile && <HomeFeatureMobile/>}
            <div style={{marginTop: 250}}/>
            <HomeTiles/>
        </PublicLayout>
    );
}
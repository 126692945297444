import React from 'react';
import styles from "./homeStyles";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(styles);

export default function HomeNews(props) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div onClick={() => history.push("/registration")} className={classes.newsLink}>
            Now Enrolling
        </div>
    );
}
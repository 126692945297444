import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import styles from "./publicStyles";
import {useHistory} from "react-router-dom";
import PublicApi from "./publicApi";
import {deleteObject, retrieveObject} from "../shared/util/storageUtil";
import {isBrowser, isMobile} from "react-device-detect";
import PublicToolbarMobile from "./publicToolbarMobile";
import PublicToolbarBrowser from "./publicToolbarBrowser";
import PublicMenuContext from "./publicMenuContext";

const useStyles = makeStyles(styles);

export default function PublicMenu(props) {
    const classes = useStyles();
    const history = useHistory();

    const onSignOut = () => {
        PublicApi.signOut(() => {
            deleteObject("user");
            deleteObject("token");
            deleteObject("cart");
            history.push({
                pathname: "/",
            })
        })
    }

    const onHome = () => {
        history.push({
            pathname: "/",
        })
    }

    const onSignIn = () => {
        history.push({
            pathname: "/signin",
        })
    }

    const onCart = () => {
        history.push({
            pathname: "/checkout/cart",
        })
    }

    const onDockOpen = () => {

    }

    const user = retrieveObject("user");
    const cart = retrieveObject("cart");

    return (
        <div className={classes.publicMenu}>
            <PublicMenuContext.Provider value={{
                onSignIn: onSignIn,
                onSignOut: onSignOut,
                onDockOpen: onDockOpen,
                onHome: onHome,
                onCart: onCart
            }}>
            {isMobile && <PublicToolbarMobile user={user} cart={cart}/>}
            {isBrowser && <PublicToolbarBrowser user={user} cart={cart}/>}
            </PublicMenuContext.Provider>
        </div>
    );
}

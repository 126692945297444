import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import styles from "./publicStyles";
import PlatformHeaderMenu from "../platform2/platformHeaderMenu";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(styles);

export default function PublicToolbarBrowser(props) {
    const classes = useStyles();
    const history = useHistory();

    const onClick = (link) => {
        history.push(link);
    }

    return (
        <AppBar
            style={{zIndex: 1200}}
            position="fixed"
            className={classes.appBar}
            classes={{
                root: classes.publicMenuAppBar,
            }}
            elevation={2}>
            <Toolbar>
                <img onClick={() => onClick("/")} alt="BHA Logo" height={80} style={{cursor: "pointer"}}
                     src={`${process.env.PUBLIC_URL}/images/header_logo_bha.png`}/>
                <div>
                    <PlatformHeaderMenu title="Home" clickHandler={() => onClick("/")} />
                    <PlatformHeaderMenu title="About Us" clickHandler={() => onClick("/about-us")} />
                    <PlatformHeaderMenu title="Registration" clickHandler={() => onClick("/registration")} />
                    <PlatformHeaderMenu title="Calendar" clickHandler={() => onClick("/calendar")} />
                    <PlatformHeaderMenu title="Downloads" clickHandler={() => onClick("/downloads")} />
                    <PlatformHeaderMenu title="Contact" clickHandler={() => onClick("/contact")} />
                </div>
            </Toolbar>
        </AppBar>
    );
}


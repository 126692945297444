import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import styles from "./publicStyles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(styles);

export default function PublicHeader(props) {
    const classes = useStyles();
    const {title, header, Icon} = props;

    return (
        <Grid container spacing={2} className={classes.headerRoot}>
            <Grid container spacing={0} className={classes.header}>
                <Grid item xs={2}>
                    <div className={classes.iconParent}>
                        <div className={classes.iconChild}>
                            <Icon className={classes.iconImage}/>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div className={classes.headerText}>
                        {title}
                    </div>
                </Grid>

            </Grid>
        </Grid>
    );
}

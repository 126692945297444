export function storeObject(key, object) {
  localStorage.setItem(key, JSON.stringify(object));
}

export function retrieveObject(key, metadata) {
  return JSON.parse(localStorage.getItem(key));
}

export function deleteObject(key) {
  localStorage.removeItem(key);
}
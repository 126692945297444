import React from 'react';
import styles from "./aboutUsStyles";
import {Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function AboutUsMissionStatement(props) {
    const classes = useStyles();

    return (
        <Paper style={{padding: 20}}>
            <Typography variant="h5">
                Blessed Hope Academy
            </Typography>
            <br/>
            <div>
                Blessed Hope Academy is a nondenominational, co-educational Christian school, which encompasses grades 9-12. The
                evangelical mission of Blessed Hope Academy is to show God’s love to a generation in conflict and crisis. Students are
                admitted based on their need for the program and their own personal concern for their future. Blessed Hope Academy
                provides a positive educational opportunity for students to rise again to continue their preparation for the future.
            </div>
            <br/>
            <Typography variant="h5">
                Mission Statement
            </Typography>
            <br/>
            <div>
                To offer hope and a future...bridging the gap between adolescence and adulthood-a critical decade when life decisions
                are made.
            </div>
            <br/>
            <Typography variant="h5">
                A Positive Approach
            </Typography>
            <br/>
            <div>
                Blessed Hope Academy is a self-paced program. We have found that time is not progress-progress is progress. Many
                people believe that more time in school will produce academic progress. It has been our experience that a
                self-motivated student with goals and individualized instruction creates a setting for progress and success. Blessed Hope
                Academy has been the leading school for this form of education in South Texas since 1975. Our Founder, an Education
                Innovator, Alice A. Ashcraft, had the vision for Blessed Hope Academy.
                <br/><br/>We believe that education is a quest for intellectual development for personal growth and for the fulfillment of God’s
                unique plan for each student. We promote the joy of learning by challenging students to do their individual best and by
                providing them with the support and resources to guide and encourage their discovery. Blessed Hope Academy
                maintains a balance between effort and achievement, the acquisition of knowledge, and academic grades with the aim
                of producing responsible citizens who are conscious of God’s word by offering hope and a future... (Jeremiah 29: 11-13).
                <br/><br/>Blessed Hope Academy offers a positive approach for high school student’s grades 9-12 to complete all requirements in
                preparation for college. Non-traditional in approach with creative flexible scheduling and possible acceleration, the
                program borrows from examples in international education communities and early American education. This unique
                system creates a dynamic educational opportunity for the students of Blessed Hope Academy.
                <br/><br/>The administrative staff and teachers provide the benefit of one-on-one tutoring to the students of Blessed Hope
                Academy. Students can master subjects at their own pace with individualized instruction.
            </div>
            <br/>
            <Typography variant="h5">
                Our Services
            </Typography>
            <br/>
            <div>
                ● Full-time students...leading to a fully accredited High School diploma.<br/>
                ● Visiting students from other public and private schools...for credit recovery and enrichment courses.<br/>
                ● Homeschool support...Blessed Hope Academy for academics and parents for home enrichment.
            </div>
            <br/>
            <Typography variant="h5">
                Statement of Faith
            </Typography>
            <br/>
            <div>
                We believe the Bible to be the inspired, infallible, authoritative, inerrant Word of God (2 Timothy 3:16, 2 Peter 1:21).<br/><br/>
                We believe there is one god, eternally in three persons: Father, Son, and Holy Spirit (Genesis 1:1, Matthew 28:19, John 10:30).<br/><br/>
                We believe in the deity of Christ (Matthew 3:17), His virgin birth (Isaiah 7:14, Matthew 1:23), His sinless life (Hebrew 4:15, 7:26), His
                miracles (John 2:11), His vicarious and atoning death (1 Corinthians 15:3, Ephesians 1:7), His bodily resurrection (John 11:25, 1
                Corinthians 15:4), His ascension to the right hand of the Father (Mark 16:19), and His personal return in power and glory (Acts 1:11,
                Revelation 19:11).<br/><br/>
                We believe in the absolute necessity of regeneration by the Holy Spirit for salvation because of the exceeding sinfulness of human
                nature, and that men are justified on the single ground of faith in the shed blood of Christ. Only by God’s grace and through faith
                alone we are saved (John 3:16-19, John 5:24, Romans 3:23, 5:8-9, Ephesians 2:8-10, Titus 3:5).<br/><br/>
                We believe in the resurrection of both the saved and the lost; they that are saved unto the resurrection of life, and they that are lost
                unto the resurrection of punishment (John 5:28-29).<br/><br/>
                We believe that man was made in a direct act of God in His image, not from previously existing life (Genesis 1:1, 22, 27, 1 Corinthians
                11:7, James 3:9).<br/><br/>
                We believe in the spiritual unity of believers in our Lord Jesus Christ (Romans 8:9, 1 Corinthians 12:12-13, Galatians 3:26-28).<br/><br/>
                We believe in the indwelling ministry of the Holy Spirit by whose presence the Christian is enabled to live a godly life (Romans
                8:13-14, 1 Corinthians 3:16, 1 Corinthians 6 19-20, Ephesians 4:30, 5:18).
            </div>
        </Paper>
    );
}
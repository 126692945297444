import React from 'react';
import styles from "./aboutUsStyles";
import {Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function AboutUsATributeToOurFounder(props) {
    const classes = useStyles();

    return (
        <Paper style={{padding: 20}}>
            <div style={{width: "100%", textAlign: "center"}}>
                <strong>Alice A. Ashcraft<br/>12/17/1941 – 04/06/2018</strong>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/images/grandmother.png`} alt="Alice A. Ashcraft" />
                </div>
            </div><br/>
            <div>
                Alice A. Wearden-Ashcraft, 76, mother, author, and educator lost her battle with cancer,
                Friday, April 6, 2018, in San Antonio, Texas. Alice was born December 17, 1941, in Floresville, Texas,
                to Dr. R.E. and Katherine Wearden.
            </div><br/>
            <div>
                Alice is the Founder and President of Blessed Hope Academy, an alternative private high school, which
                she started in San Antonio, Texas in 1975. In 2001, she received the Jefferson Award, a national
                recognition for community service. In addition, she attended a White House briefing on education in
                Washington, D.C. with her accreditation association at the request of President George H. W. Bush. Her
                school was listed as one of the “Points of Light” initiatives in all fifty states for which she received
                a banner for Model Schools of America. “Mrs. Ashcraft” as her students world-wide knew her, offered
                “hope” and “a future” to any child wanting a second chance in school. Alice also published poetry,
                various tracts, articles, editorials, testimonies, and several books, the latest being Blessed Hope-For
                God So Loved the World, which detailed much of her life and the founding of her beloved school.
            </div><br/>
            <div>
                Her children and grandchildren meant the world to her. She helped her children get to where they are in
                life, and she was instrumental in helping raise several grandchildren.
            </div><br/>
        </Paper>
    );
}
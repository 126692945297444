import React from 'react';
import styles from "./registrationStyles";
import PublicLayout from "components/layout/publicLayout";
import {School} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Card, Typography} from "@material-ui/core";
import PlatformButton from "../../components/platform/platformButton";
import downloadFile from "../../components/shared/util/fileUtil";

const useStyles = makeStyles(styles);

export default function RegistrationHome(props) {
    const classes = useStyles();

    const onDownload = (document) => {
        downloadFile(`${process.env.PUBLIC_URL}/downloads/${document}`);
    }

    return (
        <PublicLayout title="Registration" icon={School}>
            <div style={{padding: "40px 50px 0 50px"}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card className={classes.registrationCard} elevation={0}>
                            <Typography variant="h6" style={{marginBottom: 10}}>
                                2022-2023 New Student Registration Packet (PDF)
                            </Typography>
                            <PlatformButton onClick={() => onDownload("2022-2023 New Student Registration Packet.pdf")} size="md">
                                Download
                            </PlatformButton>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </PublicLayout>
    );
}
import React from 'react';
import styles from "./homeStyles";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Card, ImageList, ImageListItem, Typography} from "@material-ui/core";
import {isBrowser} from "react-device-detect";

const useStyles = makeStyles(styles);

export default function HomeTiles(props) {
    const classes = useStyles();

    const itemData = [
        {img: `${process.env.PUBLIC_URL}/images/classof2022/IMG_0492.jpeg`, cols: 1},
        {img: `${process.env.PUBLIC_URL}/images/classof2022/IMG_0496.jpeg`, cols: 2},
        {img: `${process.env.PUBLIC_URL}/images/classof2022/IMG_0489.jpeg`, cols: 1},
        {img: `${process.env.PUBLIC_URL}/images/classof2022/IMG_0494.jpeg`, cols: 1},
        {img: `${process.env.PUBLIC_URL}/images/classof2022/IMG_0481.jpeg`, cols: 1},
        {img: `${process.env.PUBLIC_URL}/images/classof2022/IMG_0501.jpeg`, cols: 2},
        {img: `${process.env.PUBLIC_URL}/images/classof2022/IMG_0511.jpeg`, cols: 1},
    ];

    return (
        <div style={{width: "90vw", marginLeft: "auto", marginRight: "auto"}}>
            <Grid container spacing={5}>
                <Grid item xs={isBrowser ? 6 : 12}>
                    <Card className={classes.homeCard} elevation={0}>
                        <Typography variant="h6" style={{marginBottom: 10}}>
                            A Positive Approach
                        </Typography>
                        <div>
                            Blessed Hope Academy is a self-paced program. We have found that time is not progress-progress is progress. Many people believe that more time in school will produce academic progress. It has been our experience that a self-motivated student with goals and individualized instruction creates a setting for progress and success.
                            <br/><br/>We believe that education is a quest for intellectual development for personal growth and for the fulfillment of God’s unique plan for each student. We promote the joy of learning by challenging students to do their individual best and by providing them with the support and resources to guide and encourage their discovery. Blessed Hope Academy maintains a balance between effort and achievement, the acquisition of knowledge, and academic grades with the aim of producing responsible citizens who are conscious of God’s word by offering hope and a future…(Jeremiah 29: 11-13).
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={isBrowser ? 6 : 12}>
                    <Card className={classes.homeCard} elevation={0}>
                        <Typography variant="h6" style={{marginBottom: 10}}>
                            News
                        </Typography>
                        <div>
                            Congratulations to our <strong>Class of 2022</strong> students!. <i>"For I know the
                            plans I have for you," declares the LORD, "plans to prosper you and not to harm you, 
                            plans to give you hope and a future".  Jeremiah 29:11</i><br/><br/>
                        </div>
                        <ImageList rowHeight={90} cols={3}>
                            {itemData.map((item) => (
                                <ImageListItem key={item.img} cols={item.cols || 1}>
                                    <img className={classes.homeTileImage} src={item.img} alt={item.title} />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
import React from 'react';
import styles from "./publicStyles";
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {isBrowser, isMobile} from "react-device-detect";

const useStyles = makeStyles(styles);

export default function PublicFooter(props) {
    const classes = useStyles();

    return (
        <div className={classes.footer} style={{height: isMobile ? 450 : 200}}>
            <Grid container spacing={2}>
                <Grid item xs={isBrowser ? 2 : 12}>
                    <img alt="BHA Logo" height={100} src={`${process.env.PUBLIC_URL}/images/logo_bha.png`}/>
                </Grid>
                <Grid item xs={isBrowser ? 3 : 12} style={{textAlign: "left"}}>
                    <strong>Blessed Hope Academy</strong><br/>
                    3355 Cherry Ridge Dr. #200A<br/>
                    San Antonio, TX 78230
                </Grid>
                <Grid item xs={isBrowser ? 3 : 12}  style={{textAlign: "left"}}>
                    <div><strong>School & Office Hours</strong></div>
                    <Grid container>
                        <Grid item xs={4}>
                            Tuesday
                        </Grid>
                        <Grid item xs={8}>
                            12:30pm – 5:30pm
                        </Grid>
                        <Grid item xs={4}>
                            Wednesday
                        </Grid>
                        <Grid item xs={8}>
                            10:00am – 1:00pm
                        </Grid>
                        <Grid item xs={4}>
                            Thursday
                        </Grid>
                        <Grid item xs={8}>
                            12:30pm – 5:30pm
                        </Grid>
                    </Grid>
                    {/*<Grid container>*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        Tuesday*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={8}>*/}
                    {/*        12:00pm – 4:00pm*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        Wednesday*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={8}>*/}
                    {/*        10:00am – 1:00pm*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        Thursday*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={8}>*/}
                    {/*        12:00pm – 4:00pm*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </Grid>
                <Grid item xs={isBrowser ? 4 : 12}  style={{textAlign: "left"}}>
                    <div><strong>Contact Us</strong></div>
                    Phone: (210) 697-9191<br/>
                    Fax: (210) 239-6337<br/>
                    E-mail: <a href="mailto:office@blessedhopeacademy.com">office@blessedhopeacademy.com</a>
                </Grid>
                <Grid item xs={12}>
                    <div style={{padding: 20, fontSize: "0.8rem"}}>Copyright © 1975-2023 Blessed Hope Academy. All rights reserved.</div>
                </Grid>
            </Grid>
        </div>
    );
}

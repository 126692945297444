import React, {useLayoutEffect, useState} from 'react';
import styles from "./homeStyles";
import {makeStyles} from "@material-ui/core/styles";
import {StyleRoot} from "radium";
import PlatformButton from "../../components/platform/platformButton";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(styles);

export default function HomeFeatureMobile(props) {
    const classes = useStyles();
    const history = useHistory();

    const onRegister = () => {
        history.push("/registration");
    }

    return (
        <div style={{position: "relative", paddingBottom: 790}}>
            <iframe style={{position: "absolute", borderBottom: "3px solid gold"}} frameBorder={0} height="1020px"
                    width="100%" src={`${process.env.PUBLIC_URL}/clouds.html`}/>
            <StyleRoot>
                <div className={classes.featureLeftText} style={{width: "100vw", left: 0, top: 40}}>
                    <div className={classes.featureText} style={{paddingLeft: "10vw", paddingRight: "10vw", width: "100vw"}}>
                        Blessed Hope Academy is a nondenominational, co-educational Christian school, which encompasses
                        grades 9-12. The evangelical mission of Blessed Hope Academy is to show God’s love to a generation
                        in conflict and crisis. <br/><br/>Students are admitted based on their need for the program and their own
                        personal concern for their future. Blessed Hope Academy provides a positive educational opportunity
                        for students to rise again to continue their preparation for the future.
                    </div>
                    <img alt="BHA Logo" style={{paddingLeft: "5vw"}} height={400} src={`${process.env.PUBLIC_URL}/images/logo_bha.png`}/>
                    <br/>
                    <br/>
                    <div style={{textAlign: "center", width: "100%"}}>
                        <PlatformButton onClick={onRegister} size="md">
                            Register Now
                        </PlatformButton>
                    </div>
                </div>
            </StyleRoot>
        </div>
    );
}
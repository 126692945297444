import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import styles from "./publicStyles";
import {Drawer, Grid, MenuItem, MenuList} from "@material-ui/core";
import PublicMenuContext from "./publicMenuContext";
import {useHistory} from "react-router-dom";
import PlatformHeaderMenu from "../platform2/platformHeaderMenu";

const useStyles = makeStyles(styles);

export default function PublicToolbarMobile(props) {
    const classes = useStyles();
    const history = useHistory();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuContext = useContext(PublicMenuContext);

    const onMenuOpen = () => {
        setMenuOpen(true);
    }

    const onMenuClose = () => {
        setMenuOpen(true);
    }

    const onClick = (link) => {
        history.push(link);
    }

    return (
        <AppBar
            classes={{
                root: classes.publicMenuAppBar,
            }}
            elevation={0}
            position="static">
            <Toolbar classes={{root: classes.publicToolbar}}>
                <IconButton onClick={onMenuOpen} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    <MenuIcon />
                </IconButton>
                <Drawer
                    anchor="left"
                    open={menuOpen}
                    onClose={onMenuClose}
                >
                    <MenuList style={{minWidth: "50vw"}}>
                        <MenuItem onClick={() => onClick("/")}>Home</MenuItem>
                        <MenuItem onClick={() => onClick("/about-us")}>About Us</MenuItem>
                        <MenuItem onClick={() => onClick("/registration")}>Registration</MenuItem>
                        <MenuItem onClick={() => onClick("/calendar")}>Calendar</MenuItem>
                        <MenuItem onClick={() => onClick("/downloads")}>Downloads</MenuItem>
                        <MenuItem onClick={() => onClick("/contact")}>Contact</MenuItem>
                    </MenuList>
                </Drawer>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                        <Typography variant="h6" className={classes.titleMobile} onClick={menuContext.onHome}>
                            Blessed Hope Academy
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
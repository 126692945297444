import React from 'react';
import styles from "./aboutUsStyles";
import {Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

export default function AboutUsAccreditation(props) {
    const classes = useStyles();

    return (
        <Paper style={{padding: 20}}>
            <Typography variant="h5">
                Accreditation
            </Typography>
            <br/>
            <div>
                Blessed Hope Academy is accredited by the Association of Christian Schools
                International. ACSI is the largest accrediting body of Christian schools and colleges
                in the world. Member schools of ACSI make no distinction concerning an
                individual’s race or ethnic background because we acknowledge there can be no
                preferential treatment with God (Romans 2:1). We are bound to extend our
                ministry to all we can reach, and we are to carry out our Lord’s Great Commission
                (Matthew 28:18).
            </div><br/>
            <div>
                ACSI, as an organization serving Christian schools, is dedicated to the
                establishment, philosophy, and promotion of fundamental Christian education
                around the world, and it neither supports nor endorses the World or National
                Council of Churches or any world, national, regional, or local organizations which
                give Christian recognition to nonbelievers or advocate a multi-faith union.
            </div><br/>
            <div>
                <img src={`${process.env.PUBLIC_URL}/images/logo_acsi.png`} alt="ACSI" />
                <br/>
                <a target="_blank" href="https://www.ACSI.org">https://www.ACSI.org</a>
            </div>
        </Paper>
    );
}
import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.10.0";
import ScrollAuto from "./components/shared/util/scrollAuto";
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import HomeHome from "./views/home/homeHome";
import AboutUsHome from "./views/aboutus/aboutUsHome";
import RegistrationHome from "./views/registration/registrationHome";
import CalendarHome from "./views/calendar/calendarHome";
import DownloadsHome from "./views/downloads/downloadsHome";
import ContactHome from "./views/contact/contactHome";

let hist = createBrowserHistory();
//EventEmitter.connect();

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    overrides: {
        MuiTab: {
            wrapper: {
                color: "#323436"
            },
            root: {
                color: '#000000',
                backgroundColor: '#f3f3f3',
                '&:hover': {
                    backgroundColor: 'rgba(108, 130, 168, 0.11764705882352941)',
                    color: '#000000',
                }
            }
        },
        MuiTableContainer: {
            root: {
                borderRadius: 0
            }
        },
        MuiTableRow: {
            root: {
                "&:hover": {
                    //backgroundColor: "#5cdb94"
                }
            }
        },
        MuiTableHead: {
            root: {
                backgroundColor: "#323436",
                borderBottomWidth: 5,
                borderBottomStyle: "solid",
                borderBottomColor: "#5cdb94",
                fontSize: "20px",
                "&:hover": {
                    //backgroundColor: "#5cdb94"
                }
            }
        },
        MuiFormHelperText: {
            contained: {
                marginLeft: 1,
                marginTop: 5
            }
        }
    }
});

ReactDOM.render(
    <Router history={hist}>
        <ThemeProvider theme={darkTheme}>
            <ScrollAuto>
                <Switch>
                    <Route path="/" exact component={HomeHome} />
                    <Route path="/about-us" exact component={AboutUsHome} />
                    <Route path="/registration" exact component={RegistrationHome} />
                    <Route path="/calendar" exact component={CalendarHome} />
                    <Route path="/downloads" exact component={DownloadsHome} />
                    <Route path="/contact" exact component={ContactHome} />
                </Switch>
            </ScrollAuto>
        </ThemeProvider>
    </Router>,
    document.getElementById("root")
);
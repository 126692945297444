const downloadsStyles = {
    downloadCard: {
        padding: 20,
        backgroundColor: "#fffdfd",
        minHeight: 100
    },
    tabRoot: {
        backgroundColor: "rgba(244,244,244,0)"
    },
};

export default downloadsStyles;
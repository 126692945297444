import React from 'react';
import styles from "./downloadsStyles";
import {Card, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PlatformButton from "../../components/platform/platformButton";
import downloadFile from "../../components/shared/util/fileUtil";

const useStyles = makeStyles(styles);

export default function DownloadsGeneral(props) {
    const classes = useStyles();

    const onDownload = (document) => {
        downloadFile(`${process.env.PUBLIC_URL}/downloads/${document}`);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        2022-2023 Parent/Student Handbook (PDF)
                    </Typography>
                    <PlatformButton onClick={() => onDownload("2022-2023 Parent Student Handbook.pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        Transcript Request Form (PDF)
                    </Typography>
                    <PlatformButton onClick={() => onDownload("Transcript Request Form.pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        Health Screening Form (PDF)
                    </Typography>
                    <PlatformButton onClick={() => onDownload("2022-2023 Health Screening Form.pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        Non-discrimination Policy (PDF)
                    </Typography>
                    <PlatformButton onClick={() => onDownload("Non-Discrimination Policy.pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        Bullying Policy (PDF)
                    </Typography>
                    <PlatformButton onClick={() => onDownload("Bullying Policy.pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        Charitable Donation Form (PDF)
                    </Typography>
                    <PlatformButton onClick={() => onDownload("Charitable Donation Form.pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        Amazon Smile Donation Instructions (PDF)
                    </Typography>
                    <PlatformButton onClick={() => onDownload("Amazon Smile Donation Instructions.pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
        </Grid>
    );
}
import React from 'react';
import styles from "./aboutUsStyles";
import {Card, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(styles);

export default function AboutUsStudentTestimonials(props) {
    const classes = useStyles();

    return (
        <div style={{width: "90vw", marginLeft: "auto", marginRight: "auto"}}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Card className={classes.testimonialCard} elevation={0}>
                        <Typography variant="h6" style={{marginBottom: 10}}>
                            Ricky’s Letter
                        </Typography>
                        <div>
                            <i>Excerpted as written and shared with permission from a letter sent to Mrs. Ashcraft from a
                                former Blessed Hope Academy student named Ricky.</i>
                            <br/><br/>
                            I came to Blessed Hope after being thrown out of Lee High School for smoking
                            pot on campus. Basically, it was just about the only school that would accept me after obtaining a criminal record for various drug offenses in San Antonio.
                            During my time at Blessed Hope, I heard and learned many things about God, the Bible and the salvation that Jesus can give to us. I refused to believe or accept this “Christianity” that was being presented to me.
                            No way, I am going to live my own life, make my own decisions, and by my own
                            rules to attain happiness.
                            <br/><br/>
                            To this day, I find it amazing that I ever graduated from school.
                            I am glad that Blessed Hope likes to give their students a fair chance.
                            After graduating, I found myself with no direction or desire to do anything but
                            to find a new way to get high.
                            <br/><br/>
                            During this period of my life, I was occasionally haunted by a statement Mrs.
                            Ashcraft at Blessed Hope had shared with me during a discussion on the reality of Christianity. Being the type that always demanded visible proof before believing anything, I thought that God would have to show me something I could touch, see or hear before I would submit to the life of a Christian.
                            <br/><br/>
                            Well, Mrs. Ashcraft told me and even wrote it down on a piece of paper that if you ask God to reveal himself to you, he will.
                            There were two profound quotes going through my mind the night I actually
                            prayed to a God I didn’t believe in.
                            <br/><br/>
                            1) “If you ask God to reveal himself to you – HE WILL.”<br/>
                            2) And from my brother, Jimmy, “How can you say that the life Christ gives us<br/>
                            is a lie when you have absolutely no experience with that life? The only way<br/>
                            to know for sure is TO TRY IT YOURSELF!”
                            <br/><br/>
                            As I prayed, “God, if you are real, you are going to have to show me because I am
                            powerless to believe. Lord, reveal yourself to me.”
                            The next morning as I lit my first cigarette of the day, what I can only describe as
                            a feeling which formed words in my soul…spoke to me and told me, “I didn’t
                            need this anymore…”
                            <br/><br/>
                            I thought about the words that I had mouthed the night before and I
                            wondered, “Could this be something real, could this be God?”
                            At that point I asked a question out in the open, not really knowing who or what I was asking for. I asked, “What about the drugs?”
                            <br/><br/>
                            Words cannot do justice to describe the joy, the peace and warmth, or the happiness I felt after asking that! I started laughing out loud! I was literally overwhelmed with the power I felt! In one moment, like a bolt of lightning, Jesus came into my life and took me…and every problem I had into his hands!
                            <br/><br/>
                            In an instant, I was delivered from a 7-year drug addiction and a completely new
                            glorious life was given to me! And I am going to live forever in the place God
                            has waiting for me!
                            <br/><br/>
                            I know the bible is true and God is real because I have experienced it myself.
                            The choice is simple: Victory in Jesus or defeat on your own.
                            <br/><br/>
                            In Christ’s Love, Ricky
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.testimonialCard} elevation={0}>
                        <Typography variant="h6" style={{marginBottom: 10}}>
                            Dahlia (RZ) (8/2020)
                        </Typography>
                        <div>
                            “Most of my teen memories for one reason or another remain foggy. However, how my high
                            school years unfolded academically are forever clear in my mind. Home-schooling was great
                            for me until I entered the 9th grade. I was already pretty much on a self-packet/paced routine,
                            which I could handle pretty well on my own until I couldn’t. I was lost, and I needed help.
                            I distinctly remember opening the phone book, (that tells you how long ago this was. HA!)
                            and looking for a school. I found Blessed Hope Academy and told my mom, ‘This is it.’ Yes,
                            that is exactly how it happened. You can ask my mom. This didn’t come without difficulties.
                            After another year or so, I got distracted, life was not kind, and I didn’t aim high. I was
                            done. But the very kind ladies of blessed hope Academy were not done with me. They encouraged
                            me and pushed me to finish. I graduated with a high school diploma in 1999. Fast forward 21
                            years…I am now a Teacher of Students with Visual Impairments. It was definitely not an easy
                            road, but I can absolutely give credit where credit is due. I am not who I am today without
                            your efforts to guide me.”
                            Thank you! --Dahlia
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.testimonialCard} elevation={0}>
                        <Typography variant="h6" style={{marginBottom: 10}}>
                            Max (F) (5/2018)
                        </Typography>
                        <div>
                            “My name is Max (F), I graduated from Blessed Hope about twelve years ago now and went on
                            to attend classes at the Savannah College of Art and Design. I was an extremely alternative
                            teenager---reading unusual literature and often bearing a challenging attitude---being
                            extremely shy and disaffected---and I have never forgotten the unconditional support that
                            found me at blessed Hope Academy. I live in Taiwan now, where I work as a business English
                            teacher, writer, and artist, in a world that I never would have had access to if not for
                            Blessed Hope.”<br/><br/>
                            “I, my family, and in fact all of my students owe Blessed Hope a tremendous debt of gratitude.
                            With all my prayers and gratitude I have to offer.” --Max

                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.testimonialCard} elevation={0}>
                        <Typography variant="h6" style={{marginBottom: 10}}>
                            Mary (M) (9/2020)
                        </Typography>
                        <div>
                            “Blessed Hope Academy made it possible for my son, Chad, to excel in his life endeavors.”
                            <br/><br/>
                            “He struggled through mainstream high school. They do not tailor learning to help the
                            creative minds. Blessed Hope gave him direction and individual support tailored for him.
                            He was able to catch up and graduate in time thanks to them. He is now in the Navy supervising
                            others and climbing the leadership ladder with a bright and successful future ahead.”
                            <br/><br/>
                            “Thank you to all the teachers and support staff at Blessed Hope for the time and effort
                            you take to help our kids succeed!”
                            Blessings, --Mary
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.testimonialCard} elevation={0}>
                        <Typography variant="h6" style={{marginBottom: 10}}>
                            Norma (DC) (10/2020)
                        </Typography>
                        <div>
                            “My daughter Alyssa graduated from Blessed Hope Academy.”
                            <br/><br/>
                            “She had and still does have so many health issues that attending regular school would have
                            been impossible.”
                            <br/><br/>
                            “My only regret is that we were not able to start in an earlier grade level. Because of the
                            individual attention that she was able to get.”
                            <br/><br/>
                            “We were blessed to find your school for Alyssa. She received a full scholarship to Our Lady
                            of the Lake University. She finished her 1st year and 3rd semester, which was then interrupted
                            by another health issue that did not let her continue.” Sincerely, --Norma
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.testimonialCard} elevation={0}>
                        <Typography variant="h6" style={{marginBottom: 10}}>
                            Jennifer (M) (10/2020)
                        </Typography>
                        <div>
                            “It brings me delight in writing this letter on behalf of Blessed Hope Academy. I have 4
                            children that have all attended Blessed Hope Academy. My oldest child, Cathleen (M), took 5
                            courses during the summer of high school. My twins, Charlotte and Stella (M), are currently
                            taking Geometry and Government and this past summer took 2 courses. My son, Robert (M), has
                            taken 4 courses in the summer of high school.”
                            <br/><br/>
                            “Mrs. Salerno and Mrs. Anderson are very supportive of their students and want them to strive
                            for success. They are always helpful and are very welcoming. The students learn so much at
                            Blessed Hope Academy and come out stronger students. Blessed Hope Academy teaches the
                            importance of time management and being accountable.”
                            Thanks, --Jennifer
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.testimonialCard} elevation={0}>
                        <Typography variant="h6" style={{marginBottom: 10}}>
                            Eric (G) (9/2020)
                        </Typography>
                        <div>
                            “My sister Monique and I attended BHA in the 80’s…and after all these years, you still emit
                            the same warmth and true love for what you do. And what you do is impact the lives of hundreds
                            of young boys and girls year after year, decade after decade, showing them opportunities
                            and giving them direction and in some cases, a true purpose. Life has afforded me and my
                            loved ones many gifts. I believe I have taken advantage of these gifts, in part, because of
                            you.”
                            <br/><br/>
                            “I sense the exact same positive energy and endless determination as the day I left BHA.
                            Your drive is unwavering. You are a pillar of Christian strength and you have set the best
                            example of what faith and belief can achieve. If there are Angels walking among us, Sally
                            Anderson, you are one of them.”
                            God bless you and yours, --Eric
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.testimonialCard} elevation={0}>
                        <Typography variant="h6" style={{marginBottom: 10}}>
                            Tammy (S) (9/2020)
                        </Typography>
                        <div>
                            “Blessed Hope Academy has been a blessing to me and my son, T. He started there last November.
                            T is a bright, capable student. He has always done well in school and been confident in
                            himself whether it be academics, sports, or social gatherings. Being a leader and positive
                            influence was a strength of his as well. He has taken Pre-AP classes and done well, making
                            all ‘A’s in his classes through middle school. When he started his Freshman year at a public
                            high school, he was ready to have a terrific year. He made the basketball team; he was taking
                            AP and Pre-AP classes and was ready to conquer the world. He started out doing okay but then
                            soon discovered that some teachers don’t teach. This was the situation in two of his Pre-AP
                            classes. He also discovered that some teachers are not there for kids and will not like some
                            of the students and try to make life difficult for them. This was the case with one of his
                            teachers who targeted him. T was not used to this because he had always been well-liked by
                            his teachers. I could see his confidence deteriorate and he began thinking he wasn’t very smart.
                            The second semester, he started having anxiety and didn’t want to attend school. A few months
                            later he became depressed. I kept pushing him thinking he would overcome this. We made it
                            through Freshman year, mainly because of his supportive, caring coaches.”
                            <br/><br/>
                            “He began his Sophomore year at the same public school. We were going in with a good attitude
                            thinking it was going to be a great year. He liked his teachers, but the anxiety started back.
                            He could not physically get up and go to school. I knew I had to do something. I withdrew him
                            and there was an advertisement about public school online. I enrolled him in the online school.
                            This was disastrous. They had placed him in classes that he had already taken and couldn’t
                            seem to get it fixed. So, I knew there had to be something else for him. We visited some
                            private schools. After meeting with Sally at Blessed Hope Academy, T immediately said, ‘I
                            want to attend there.’ I was hesitant because I am a single parent and teacher, and this was
                            going to impact us financially. I knew I had to do what was best for him and his two brothers
                            could sacrifice things for him. So, I enrolled him. Sally went over the classes he had taken
                            and filled out a plan of what he needed for his Sophomore year, as well as the requirements
                            for him to graduate from here. They have instructed him over concepts he was unsure about
                            and allowed him to gain more knowledge in those areas. Sally and Kathy have been fantastic.
                            They have supported and encouraged T and yet maintained their standards. They have pushed him
                            and corrected him when he has not done what he has needed without causing him more anxiety.
                            That is an art in itself. He is still struggling with depression, but the staff at Blessed
                            Hope Academy is there for him. I asked T if he wanted to go back to public school and he
                            firmly told me, ‘No.’ He feels he is in the right place…so do I.”
                            <br/><br/>
                            “I have had people ask me if I am concerned he won’t be ready for college. I tell them I
                            think this program better prepares them for college. They have to work independently and
                            have time management skills. I am so thankful for this school and staff. I feel if it wasn’t
                            for Blessed Hope Academy, T would have quit school. No parent wants that for their child.”
                            Sincerely, --Tammy
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
import React from "react";
import PropTypes from "prop-types";
import {createTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {lightGreen} from '@material-ui/core/colors';
import styles from "./platformStyles";
import {withStyles} from "@material-ui/core";
import cx from "classnames";

const useStyles = makeStyles(styles);

const theme = createTheme({
    palette: {
        primary: lightGreen,
    },
});

export default function PlatformButton(props) {
    const { children, fullWidth, size, onClick, outlined, inline, ...allProps } = props;
    const classes = useStyles();

    let combinedClassName = cx({
        [classes.platformButtonSmall]: size && size === "sm",
        [classes.platformButtonMedium]: size && size === "md",
        [classes.platformButtonLarge]: size && size === "lg",
        [classes.platformButtonXLarge]: size && size === "xl",
        [classes.platformButtonFullWidth]: fullWidth,
        [classes.platformButtonOutlined]: outlined,
        [classes.platformButtonContained]: !outlined,
        [classes.platformButtonInline]: inline,
        [classes.platformButton]: true
    });

    return (
        <ThemeProvider theme={theme}>
            <ColorButton
                {...allProps}
                onClick={onClick}
                disableElevation
                color="primary"
                classes={{
                    root: combinedClassName,
                }}
                variant={outlined ? "outlined" : "contained"}>
                {children}
            </ColorButton>
        </ThemeProvider>
    );
}

const ColorButton = withStyles((theme) => ({
    root: {
        color: "white",
        //backgroundColor: "magenta",
        //backgroundColor: "#4d4d4d",
        fontWeight: "bold",
        '&:hover': {
            backgroundColor: "#5cdb94"//"rgba(129,217,168,0.79)",
        },
    },
}))(Button);

PlatformButton.propTypes = {
    children: PropTypes.any
}
import {isBrowser} from "react-device-detect";

const drawerWidth = 240;

const publicStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    dockBody: {
        backgroundColor: "#323436"
    },
    publicMenuAppBar: {
        backgroundColor: "#5390cc",
        color: "black"
    },
    menuButton: {
        marginRight: 2,
        color: "#ffffff"
    },
    titleBrowser: {
        marginRight: "3vw",
        cursor: "pointer",
        color: "#ffffff"
    },
    titleMobile: {
        cursor: "pointer",
        paddingRight: 30
    },
    rootPadding: {
        //margin: "20px 50px 50px 50px"
    },
    publicMenu: {

    },
    searchInput: {
        height: 20
    },
    publicAvatar: {
        right: -3,
        top: 13,
        border: `2px solid #efef`,
        padding: '0 4px',
    },
    publicToolbar: {
        color: "white",
        minHeight: 0,
        height: 50,
    },
    publicCrumbs: {
        margin: "10px 0 10px 0",
        paddingLeft: "15%",
        paddingRight: "10%"
    },
    notificationText: {
        textDecoration: "underline",
        fontWeight: 400,
        textTransform: "none",
        color: "black"
    },
    notification: {
        height: 50,
        //backgroundColor: "#8de4af",
        backgroundColor: "#efef",
        textAlign: "center",
        color: "white",
        width: "100%"
    },
    containerMobile: {
        position: "relative",
        margin: "0 10px 10px 20px",
        width: "90vw",
    },
    containerBrowser: {
        position: "relative",
        width: "30vw",
    },
    suggestionsContainerOpen: {
        position: "absolute",
        zIndex: 1,
        marginTop: 5,
        left: 0,
        right: 0
    },
    suggestion: {
        display: "block"
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none"
    },
    publicMenuAvatarButton: {
        width: 30,
        height: 30,
        margin: 10,
        cursor: "pointer"
    },
    publicMenuAvatar: {
        width: 50,
        height: 50,
    },
    dockRoot: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    dockMenuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        marginTop: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        position: "relative",
        //backgroundColor: "black",
        backgroundColor: "#383a3c",
        //backgroundImage: "radial-gradient(circle at top center, #27472f 5px, #000)",
        flexGrow: 1,
        minHeight: "100vh"
        //marginTop: 70,
        //padding: theme.spacing(3),
    },
    footer: {
        padding: 20,
        left: 0,
        bottom: 0,
        width: "100%",
        color: "black",
        textAlign: "center",
        backgroundColor: "#d9d9cc",
        height: 200
    },
    headerControls: {
        textAlign: "right",
        padding: 35
    },
    headerText: {
        color: "#192546",
        opacity: 0.65,
        fontSize: isBrowser ? 44 : 24,
        letterSpacing: isBrowser ? "-3px !important" : "-1px !important",
        height: isBrowser ? 100 : 50,
        fontWeight: 400,
        top: isBrowser ? 40 : 16,
        marginLeft: isBrowser ? 30 : 60,
        position: "absolute",
        right: isBrowser ? "inherit" : 40
    },
    headerRoot: {
        position: "relative",
        marginTop: isBrowser ? 80 : 0,
        height: isBrowser ? 110 : 55,
        backgroundColor: "#d9d9cc",
    },
    header: {
        height: 40,
    },
    iconImage: {
        fontSize: isBrowser ? 100 : 50,
        opacity: 0.5,
        marginTop: 10
    },
    iconParent: {
        height: isBrowser ? 108 : 54,
        marginLeft: 20,
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/header_icon_bg.png)`,
        backgroundSize: "cover",
        position: "relative",
        minWidth: isBrowser ? "inherit" : 100
    },
    iconChild: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
});
////background: "linear-gradient(270deg, rgba(133,219,145, 1), rgba(100,179,118,1) 90%)",content
export default publicStyles;

import React from 'react';
import styles from "./calendarStyles";
import PublicLayout from "components/layout/publicLayout";
import {EventNote} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {Card, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import PlatformButton from "../../components/platform/platformButton";
import downloadFile from "../../components/shared/util/fileUtil";
import {isBrowser} from "react-device-detect";

const useStyles = makeStyles(styles);

export default function CalendarHome(props) {
    const classes = useStyles();

    const onDownload = (document) => {
        downloadFile(`${process.env.PUBLIC_URL}/downloads/${document}`);
    }

    return (
        <PublicLayout title="Calendar" icon={EventNote}>
            <div style={{padding: isBrowser ? "40px 50px 0 50px" : "20px 25px 0 25px"}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card className={classes.calendarCard} elevation={0}>
                            <Typography variant="h6" style={{marginBottom: 10}}>
                                2023-2024 Fall Semester
                            </Typography>
                            <Typography variant="subtitle1" style={{marginBottom: 10}}>
                                August 16th - December 22nd
                            </Typography>
                            <Grid container>
                                <Grid item xs={isBrowser ? 2 : 5}>
                                    <strong>Monday</strong>
                                </Grid>
                                <Grid item xs={isBrowser ? 10 : 7}>
                                    Closed
                                </Grid>
                                <Grid item xs={isBrowser ? 2 : 5}>
                                    <strong>Tuesday</strong>
                                </Grid>
                                <Grid item xs={isBrowser ? 10 : 7}>
                                    12:00pm – 4:00pm (Must sign in by 3:30pm to test)
                                </Grid>
                                <Grid item xs={isBrowser ? 2 : 5}>
                                    <strong>Wednesday</strong>
                                </Grid>
                                <Grid item xs={isBrowser ? 10 : 7}>
                                    10:00am – 1:00pm (Must sign in by 12:30pm to test)
                                </Grid>
                                <Grid item xs={isBrowser ? 2 : 5}>
                                    <strong>Thursday</strong>
                                </Grid>
                                <Grid item xs={isBrowser ? 10 : 7}>
                                    12:00pm – 4:00pm (Must sign in by 3:30pm to test)
                                </Grid>
                                <Grid item xs={isBrowser ? 2 : 5}>
                                    <strong>Friday</strong>
                                </Grid>
                                <Grid item xs={isBrowser ? 10 : 7}>
                                    Closed
                                </Grid>
                            </Grid>
                            <br/>
                            <Typography variant="subtitle1" style={{marginBottom: 10}}>
                                Holidays
                            </Typography>
                            <div>
                                Thanksgiving    - November 24th
                                Christmas Break - December 23rd - January 2nd
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card className={classes.calendarCard} style={{minHeight: 50}} elevation={0}>
                            <Typography variant="h6" style={{marginBottom: 10}}>
                                2022-2023 School Calendar (PDF)
                            </Typography>
                            <PlatformButton onClick={() => onDownload("2022-2023 School Calendar.pdf")} size="md">
                                Download
                            </PlatformButton>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </PublicLayout>
    );
}
import React from 'react';
import styles from "./downloadsStyles";
import {Card, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PlatformButton from "../../components/platform/platformButton";
import downloadFile from "../../components/shared/util/fileUtil";

const useStyles = makeStyles(styles);

export default function DownloadsStudentResources(props) {
    const classes = useStyles();

    const onDownload = (document) => {
        downloadFile(`${process.env.PUBLIC_URL}/downloads/students/${document}`);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        Algebra 1 Video Tutor (1097-1108).pdf
                    </Typography>
                    <PlatformButton onClick={() => onDownload("Algebra 1 Video Tutor (1097-1108).pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        Algebra II Video Tutor (1121-1132).pdf
                    </Typography>
                    <PlatformButton onClick={() => onDownload("Algebra II Video Tutor (1121-1132).pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        Biology Labs (1097-1108).pdf
                    </Typography>
                    <PlatformButton onClick={() => onDownload("Biology Labs (1097-1108).pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        French Audio Lessons (97-108).pdf
                    </Typography>
                    <PlatformButton onClick={() => onDownload("French Audio Lessons (97-108).pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        Geometry Video Tutor (1109-1120).pdf
                    </Typography>
                    <PlatformButton onClick={() => onDownload("Geometry Video Tutor (1109-1120).pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        IPC-Chemistry-Physics Labs (1109-1120).pdf
                    </Typography>
                    <PlatformButton onClick={() => onDownload("IPC-Chemistry-Physics Labs (1109-1120).pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.downloadCard} elevation={0}>
                    <Typography variant="h6" style={{marginBottom: 10}}>
                        Spanish I Video Lessons.pdf
                    </Typography>
                    <PlatformButton onClick={() => onDownload("Spanish I Video Lessons.pdf")} size="md">
                        Download
                    </PlatformButton>
                </Card>
            </Grid>
        </Grid>
    );
}
import {isBrowser} from "react-device-detect";

const aboutUsStyles = {
    tabRoot: {
        backgroundColor: "rgba(244,244,244,0)",
        maxWidth: isBrowser ? "inherit" : "100%"
    },
    testimonialCard: {
        padding: 20,
        backgroundColor: "#fffdfd"
    },
};

export default aboutUsStyles;